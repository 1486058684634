<template>
  <div class="page-content">
    <div class="card row">
      <div class="card-body col-md-12">
        <div class="row d-flex align-items-center">
          <div class="col-6 col-sm-6">
            <form class="search-form w-100" @submit.prevent="handlerSearch()">
              <div class="input-group border rounded-sm">
                <div class="input-group-prepend">
                  <div class="input-group-text border-0 rounded-sm">
                    <FeatherIcon type="search" class="icon-md cursor-pointer" />
                  </div>
                </div>
                <input v-model="search.search_keyword" type="text" class="form-control  border-0 rounded-sm" id="searchForm" placeholder="Search here by division name...">
              </div>
            </form>
          </div>
          <div class="col-3 col-sm-3">
            <button class="btn btn-primary btn-block" @click="handlerBtnCreate">Create New Division</button>
          </div>
        </div>
        <div class="mt-2" v-loading="loading.table">
          <table class="table table-hover">
            <thead>
              <tr>
                <th>Division Name</th>
                <th width="20%">Workspaces Assigned</th>
                <th>Total Members</th>
                <th>Created Date</th>
                <th colspan="4">#</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="item in rows" :key="item.id">
                <td>{{ item.name }}</td>
                <td style="word-wrap: break-word; min-width: 450px; white-space: initial;">
                  <el-tag size="mini" v-for="ws in item.workspaces" :key="ws._id" class="mr-2 mb-2">{{ ws.name }}</el-tag>
                </td>
                <td>{{ item.total_member }} user(s)</td>
                <td>{{ item.created_at_str }}</td>
                <td>
                  <button class="btn btn-outline-primary btn-sm mr-2" @click="handlerViewBtn(item.id)">
                    <font-awesome icon="list"></font-awesome>
                  </button>
                  <button class="btn btn-outline-primary btn-sm mr-2" @click="handlerEditBtn(item)">
                    <font-awesome icon="edit"></font-awesome>
                  </button>
                  <button class="btn btn-outline-primary btn-sm" @click="handlerBtnDelete(item.id)">
                    <font-awesome icon="times"></font-awesome>
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
          <el-empty v-if="!total" description="No data"></el-empty>
          <b-pagination v-if="total > search.per_page" v-model="currentPage" :total-rows="total" :per-page="search.per_page"/>
        </div>
      </div>
    </div>
    <create-divisions :show="show_modal.create" @onHide="show_modal.create = false"/>
    <edit-divisions :show="show_modal.edit" :group_id="selected_group.id" :current_data="selected_group" @onHide="onHideModalEdit"/>
  </div>
</template>
<script>
import moment from 'moment';
import { isEqual } from 'lodash';
import divisionsApi from '@/api/divisions';
import popupErrorMessages from '../../../library/popup-error-messages';
import CreateDivisions from './Create.vue';
import EditDivisions from './Edit.vue';

export default {
  name: 'DivisionsIndex',
  components: {
    CreateDivisions,
    EditDivisions,
  },
  metaInfo: {
    title: 'Divisions',
  },
  data() {
    return {
      loading: {
        table: false,
      },
      show_modal: {
        create: false,
      },
      search: {
        page: this.$route.query.page || 1,
        per_page: this.$route.query.per_page || 10,
        search_keyword: this.$route.query.search_keyword || '',
        search_by: this.$route.query.search_by || 'name',
      },
      rows: [],
      total: 0,
      loaderStack: 0,
      loader: null,
      selected_group: {},
    };
  },
  async mounted() {
    this.showLoader();
    await this.getList();
    this.hideLoader();
  },
  computed: {
    currentPage: {
      get() {
        return this.search.page;
      },
      set(val) {
        this.search.page = val;
        this.getList();
      },
    },
  },
  methods: {
    showLoader() {
      if (this.loaderStack === 0) {
        this.loader = this.$loading.show();
      }
      this.loaderStack += 1;
    },
    hideLoader() {
      this.loaderStack -= 1;
      if (this.loaderStack === 0) {
        this.loader.hide();
      }
    },
    handlerBtnCreate() {
      this.show_modal.create = true;
    },
    handlerSearch() {
      this.search.page = 1;
      this.getList();
    },
    async getList() {
      this.loading.table = true;
      const options = {
        page: this.search.page.toString(),
        per_page: this.search.per_page.toString(),
        search_by: this.search.search_by.toString(),
        search_keyword: this.search.search_keyword.toString(),
      };
      if (!isEqual(options, this.$route.query)) {
        this.$router.replace({ query: options });
      }
      const response = await divisionsApi.getList(options);
      this.loading.table = false;
      popupErrorMessages(response);
      const { data } = response;
      this.rows = data.rows.map((v) => {
        v.created_at_str = moment(v.created_at).format('DD MMM YYYY, HH:mm');
        return v;
      });
      this.total = data.count;
    },
    handlerBtnDelete(id) {
      this.$confirm(this.$t('divisions.confirm_delete'), this.$t('general.confirmation'), {
        confirmButtonText: this.$t('general.yes'),
        cancelButtonText: this.$t('general.no'),
        type: 'warning',
        center: true,
        /* eslint-disable no-param-reassign */
        beforeClose: async (action, instance, cb) => {
          if (action === 'confirm') {
            instance.confirmButtonLoading = true;
            await divisionsApi.delete(id)
              .then(async (response) => {
                await popupErrorMessages(response);
                this.$message({
                  message: this.$t('divisions.success_delete'),
                  type: 'success',
                });
                this.search.page = 1;
                this.search.search_keyword = '';
                this.getList();
              });
          }
          instance.confirmButtonLoading = false;
          cb();
        },
      }).catch(() => {});
    },
    handlerViewBtn(id) {
      this.$router.push({
        name: 'Division Members',
        params: {
          id,
        },
      });
    },
    handlerEditBtn(item) {
      this.selected_group = item;
      this.$set(this.show_modal, 'edit', true);
    },
    onHideModalEdit() {
      // this.show_modal.edit = false;
      this.$set(this.show_modal, 'edit', false);
    },
  },
};
</script>
